import React, {useState} from 'react';
import {Dropdown, Input, Select} from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Modal} from 'antd';
import './style.scss';
import SelectCityIcon from '../Icons/SelectCity';
import * as applicationActions from '../../actions/applicationActionCreators';
import ChevronIcon from '../Icons/Chevron';
import CloseIcon from '../Icons/Close';
import MobileSearchString from '../Header/Mobile/SearchString';

const SelectCity = (props) => {
  const
    [query, setQuery] = useState(''),
    [open, setOpen] = useState(false),
    handleClose = () => {
      setOpen(false);
    },
    cities = query ? props.applicationState.cities.filter(city => city.name.toLowerCase().includes(query.toLowerCase())) : props.applicationState.cities,
    currentCity = props.selected || props.applicationState.current_city,
    handleSelectCity = (branchId) => () => {
      if (props.onSelect) {
        const branch = props.applicationState.branches.find(branch => branch.id === branchId);
        props.onSelect(branch, props.applicationState.cities.find(city => city.id === branch.city_id));
        setOpen(false);
      } else {
        props.applicationActions.setCurrentCity(branchId)
      }
    },

    handleInputChange = (e) => {
      setQuery(e.target.value);
    };

  return (
    <div className='select-city'>
      <button className='select-city__selector' onClick={() => setOpen(true)}>
        <SelectCityIcon />
        <span className='select-city__title'>{props.applicationState.current_city.name}</span>
      </button>
      <Modal
        open={open}
        onCancel={handleClose}
        width={600}
        title='Ваш регион'
        closeIcon={<CloseIcon height={20} width={20} />}
        footer={null}
      >
        <div className='select-city__modal'>
          <div className='select-city__search'>
            <MobileSearchString
              query={query}
              placeholder='Поиск по региону'
              className='desktop'
              onChange={handleInputChange}
              onClear={() => setQuery('')}
            />
          </div>
          <ul className='select-city__list'>
            {
              cities.map((city) => (
                <li
                  key={city.id}
                  className={`select-city__list-item ${city.id === currentCity.id ? 'select-city__list-item_active' : ''}`}
                  onClick={handleSelectCity(city.main_branch.id)}
                >
                  <span>{city.name}</span>
                </li>
              ))
            }
          </ul>
        </div>
      </Modal>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState, railsContext}) => {
    return {
      applicationState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(SelectCity);
