import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as articlesActions from '../../actions/articlesActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount} from 'react-use';
import ArticlesList from '../../components/Articles/List';
import ProductsList from '../../components/ProductsList';

const StocksPage = (props) => {
  const
    collection = props.articlesState.stocks,
    {is_mobile, current_city} = props.applicationState;

  useMount(() => {
    props.articlesActions.fetchStocks();
    props.articlesActions.fetchStocksProducts();
  });

  return (
    <Layout
      className='stocks-page'
      breadcrumbs={[
        {label: 'Акции'}
      ]}
    >
      <div className='container'>
        <section className='page-content-section'>
          <ArticlesList collection={collection} linkHelper={constants.ROUTES.REGIONAL_STOCK_PATH} />
        </section>
        {
          props.articlesState.stocksOffers?.length ?
            <section className='page-content-section'>
              <h3>
                Товары, участвующие в акциях
              </h3>
              <ProductsList products={props.articlesState.stocksOffers} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
            </section>
            : null
        }
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      articlesActions: bindActionCreators(articlesActions, dispatch)
    };
  },

  mapStateToProps = ({articlesState, railsContext, applicationState}) => {
    return {
      applicationState,
      articlesState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(StocksPage));
