import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as productsActions from '../../../actions/productsActionCreators';
import * as cartActions from '../../../actions/cartActionCreators';
import AnimatedNumber from "animated-number-react";
import Button from '../../Button';

import './style.scss';
import './mobile.scss';
import {isFloatPrice, priceFormat, priceFormatInt} from '../../../tools/formatters';
import AvailabilityWidget from '../AvailabilityWidget';
import CommonWidget from '../CommonWidget';
import LocationLargeIcon from '../../Icons/LocationLarge';
import DeliveryIcon from '../../Icons/Delivery';
import {Drawer} from 'antd';
import CloseIcon from '../../Icons/Close';
import CalcIcon from '../../Icons/Calc';
import constants from '../../../constants';
import AvailabilityDialog from '../AvailabilityDialog';

const OrderWidget = ({offer, ...props}) => {
  const
    [orderUnit, setOrderUnit] = useState(null),
    [availabilityDialogOpen, setAvailabilityDialogOpen] = useState(false),
    {is_mobile} = props.applicationState,
    [animationDuration, setAnimationDuration] = useState(300),
    currentUnit = orderUnit || offer.baseUnit,
    currentPrice = offer.new_price ? offer.newPriceInUnits(currentUnit) : offer.priceInUnits(currentUnit),
    oldPriceValue = offer.priceInUnits(currentUnit),
    discount = offer.discountPercents,
    handleAvailabilityClick = () => {
      console.log('click');
      setAvailabilityDialogOpen(true);
    },
    handleChangeUnit = (unit) => () => {
      setOrderUnit(unit);
      // animationDuration === 0 && setAnimationDuration(300);
    },
    handleAddToCart = () => {
      props.cartActions.addToCart(offer.id, 1, currentUnit?.id);
    };
  return (
    <div className='product-order-widget'>
      <div className='product-order-widget__row'>
        <div className='large-price-box'>
          <ul className='units-select'>
            {
              offer.units.map((unit) => {
                const unitIsActive = unit.id === orderUnit?.id || (!orderUnit && unit.base_line);
                return (
                  <li
                    className={`units-select__item ${unitIsActive ? 'units-select__item_active' : ''}`}
                    onClick={handleChangeUnit(unit)}
                  >
                    {unit.name}
                  </li>
                )
              })
            }
          </ul>
          <div className='large-price-box__price-value'>
            <AnimatedNumber
              value={currentPrice}
              duration={animationDuration}
              formatValue={isFloatPrice(currentPrice) ? priceFormat : priceFormatInt}
            />
            <span>₽</span>
          </div>
          {
            offer.new_price ?
              <div className='large-price-box__old-price'>
                {discount ? <div className='products-list-item__label products-list-item__label_discount'>-{discount}%</div> : null}
                <div className='large-price-box__old-price-value decoration-line-through'>
                  <AnimatedNumber
                    value={oldPriceValue}
                    duration={animationDuration}
                    formatValue={isFloatPrice(oldPriceValue) ? priceFormat : priceFormatInt}
                  />
                  <span>₽</span>
                </div>
                <div className='large-price-box__auth-info'>+ 232 ₽ после авторизации</div>
              </div>
              : null
          }
        </div>
      </div>
      <div className='product-order-widget__row'>
        <Button
          primary
          className='lg w-full'
          onClick={handleAddToCart}
        >
          Добавить в корзину
        </Button>
      </div>
      <div className='product-order-widget__row product-order-widget__group '>
        <AvailabilityWidget offer={offer} currentUnit={currentUnit} onClick={is_mobile ? handleAvailabilityClick : null} />
        <CommonWidget icon={<LocationLargeIcon outlined={is_mobile} />} title='Самовывоз сейчас и позже' value='Бесплатно' onClick={is_mobile ? () => false : null} />
        <CommonWidget icon={<DeliveryIcon outlined={is_mobile} />} title='Доставка сегодня и позже' value='от 350 ₽' onClick={is_mobile ? () => false : null} />
      </div>
      <AvailabilityDialog
        open={availabilityDialogOpen}
        onClose={() => setAvailabilityDialogOpen(false)}
        currentUnit={currentUnit}
        offer={offer} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch),
      cartActions: bindActionCreators(cartActions, dispatch),
    };
  },

  mapStateToProps = ({productsState, railsContext, applicationState}) => {
    return {
      applicationState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(OrderWidget);
